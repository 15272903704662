.about .panels {
    display: flex;
    height: 100%;
}

.about .picture {
    width: 50%;
    height: 100%;
}

/* .pic {
    height: 100%;
} */


.about .description {
    width: 50%;
    background-color: rgb(245, 245, 245);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.about .text h1 {
    font-size: 42px;
    color: #547099;
}

.about .text h2 {
    font-size: 24px;
}

.about .text p {
    line-height: 1.75;
}

img {
    max-width: 100%;
    max-height: 100%;
}