body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Glacial Indifference",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 100 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Glacial Indifference", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 100 !important;
}
