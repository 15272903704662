
.team .title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    background-color: #b9e1f9;
}

.team .title h1{
    font-size: 40px;
    font-weight: 700;
}

.all-execs{
    margin: auto;
    margin-top: 3.5rem;
}

.exec{
    background-color: white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    font-size: 17px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.3rem;
    border-radius: 11px;
    width: 20%;
    height: auto;
    margin: 1rem 1.3rem 2rem 1rem;
    position: relative;
}

.team img{
    border-radius: 9px;
}

.team p{
    margin-top: 0rem;
    padding-top: 0rem;
    font-size: 20px;
    padding-bottom: 0rem;
}

.team h2{
    margin-top: 1.5rem;
}


